export const columns = [
	{
		title: 'Pro MŠ',
		image: {
			src: 'https://krouzky-kamarad.cz/img/krouzky/ms.png',
			alt: ''
		},
		color: 'ms-green',
		page: 'ms',
		template: `Zájmové kroužky Kamarád pro děti mateřských škol. Vyberte si z naší pestré nabídky kroužků.`
	},
	{
		title: 'Pro ZŠ',
		image: {
			src: 'https://krouzky-kamarad.cz/img/krouzky/zs.png',
			alt: ''
		},
		color: 'orange',
		page: 'zs',
		template: `Zájmové kroužky Kamarád pro děti základních škol. Vyberte si z naší pestré nabídky kroužků.`
	}
];

export const ms = [
	{
		title: 'Sportovní',
		image: {
			src: 'https://krouzky-kamarad.cz/img/ms/sportovni.png',
			alt: ''
		},
		color: 'ms-green',
		list: [
			{
				title: 'Sportovní kroužek Kamarád',
				page: 'sportovni-krouzek'
			},
			{
				title: 'Jóga a zdravotní cvičení ',
				page: 'joga'
			},
			{
				title: 'Sportovní gymnastika ',
				page: 'gymnastika'
			},
			{
				title: 'Karate pro nejmenší ',
				page: 'karate'
			},
			{
				title: 'Fotbal',
				page: 'fotbal'
			}
		]
	},
	{
		title: 'Taneční',
		image: {
			src: 'https://krouzky-kamarad.cz/img/ms/tanecni.png',
			alt: ''
		},
		color: 'orange',
		list: [
			{
				title: 'Tanečky s prvky baletu ',
				page: 'tanecky'
			},
			{
				title: 'Zumba pro nejmenší ',
				page: 'zumba'
			},
			{
				title: 'Street Dance ',
				page: 'street-dance'
			},
			{
				title: 'Disco dance aneb tančíme na moderní hudbu s Kamarádem',
				page: 'disco'
			},
		]
	},
	{
		title: 'Jazykové',
		image: {
			src: 'https://krouzky-kamarad.cz/img/ms/jazykove.png',
			alt: ''
		},
		color: 'pink',
		list: [
			{
				title: 'Český jazyk s Kamarádem',
				page: 'logopedie'
			},
			{
				title: 'Hravá angličtina',
				page: 'hrava-anglictina'
			},
			{
				title: 'Angličtina – úroveň rodilý mluvčí',
				page: 'anglictina-rodily-mluvci'
			},
			{
				title: 'Čtenářský kroužek Kamarád',
				page: 'ctenarsky-krouzek'
			},
		]
	},
	{
		title: 'Naučné',
		image: {
			src: 'https://krouzky-kamarad.cz/img/ms/naucne.png',
			alt: ''
		},
		color: 'blue',
		list: [
			{
				title: 'Malý astronom a badatel',
				page: 'maly-astronom'
			},
			{
				title: 'Vědecké pokusy Kamarád',
				page: 'vedecke-pokusy'
			},
			{
				title: 'Harry Potter, vítejte v Bradavicích!',
				page: 'ms-harry-potter-vitejte-v-bradavicich'
			},
			{
				title: 'Malý přírodovědec a skaut junior',
				page: 'maly-skaut'
			},
		]
	},
	{
		title: 'Hudební',
		image: {
			src: 'https://krouzky-kamarad.cz/img/ms/hudebni.png',
			alt: ''
		},
		color: 'green',
		list: [
			{
				title: 'Flétna',
				page: 'fletna'
			},
			{
				title: 'Zpíváme s kytarou a djembe',
				page: 'zpivame'
			},
		]
	},
	{
		title: 'Divadelní',
		image: {
			src: 'https://krouzky-kamarad.cz/img/ms/divadelni.png',
			alt: ''
		},
		color: 'purple',
		list: [
			{
				title: 'Loutkové a maňáskové divadlo',
				page: 'loutkove'
			},
			{
				title: 'Hudebně‐dramatický kroužek',
				page: 'hudebne-dramaticky-krouzek'
			}
		]
	},
	{
		title: 'Výtvarné',
		image: {
			src: 'https://krouzky-kamarad.cz/img/ms/vytvarne.png',
			alt: ''
		},
		color: 'yellow',
		list: [
			{
				title: 'Výtvarné dílny Kamarád',
				page: 'vytvarne-dilny'
			},
			{
				title: 'Keramika',
				page: 'keramika'
			},
			{
				title: 'Tvořivé dílny a výroba šperků',
				page: 'tvorive-dilny'
			},
		]
	},
];

export const zs = [
	{
		title: 'Sportovní',
		image: {
			src: 'https://krouzky-kamarad.cz/img/zs/sportovni.png',
			alt: ''
		},
		color: 'ms-green',
		list: [
			{
				title: 'Všestranný sportovní kroužek',
				page: 'vseobecny-sportovni-krouzek'
			},
			{
				title: 'Sportovní gymnastika ',
				page: 'sportovni-gymnastika'
			},
			{
				title: 'Jóga pro děti',
				page: 'joga-pro-deti'
			},
			{
				title: 'Karate pro děti ',
				page: 'karate-pro-deti'
			},
			{
				title: 'Judo pro děti',
				page: 'judo'
			},
			{
				title: 'Bojové sporty a sebeobrana',
				page: 'bojove-sporty'
			},
			{
				title: 'Parkour',
				page: 'parkour'
			},
			{
				title: 'Florbal',
				page: 'florbal'
			},
			{
				title: 'Fotbal',
				page: 'zs-fotbal'
			},
			{
				title: 'Futsal',
				page: 'futsal'
			},
			{
				title: 'Míčové a pohybové hry',
				page: 'micove-a-pohybove-hry'
			},
			{
				title: 'Aerobik',
				page: 'aerobik'
			},
			{
				title: 'Atletika',
				page: 'zs-atletika'
			},
		]
	},
	{
		title: 'Taneční',
		image: {
			src: 'https://krouzky-kamarad.cz/img/ms/tanecni.png',
			alt: ''
		},
		color: 'orange',
		list: [
			{
				title: 'Scénický tanec',
				page: 'scenicky-tanec'
			},
			{
				title: 'Contemporary dance',
				page: 'contemporary-dance'
			},
			{
				title: 'Street Dance ',
				page: 'zs-street-dance'
			},
			{
				title: 'Zumba',
				page: 'zs-zumba'
			},
			{
				title: 'Hula hoop',
				page: 'hula-hoop'
			},
			{
				title: 'Roztleskávačky',
				page: 'roztleskavacky'
			},
			{
				title: 'Modern dance',
				page: 'modern-dance'
			},
		]
	},
	{
		title: 'Výtvarné',
		image: {
			src: 'https://krouzky-kamarad.cz/img/ms/vytvarne.png',
			alt: ''
		},
		color: 'yellow',
		list: [
			{
				title: 'Tvořivé dílny a výroba šperků',
				page: 'zs-tvorive-dilny'
			},
			{
				title: 'Základy komiksu a ilustrace pro děti',
				page: 'komiks'
			},
			{
				title: 'Keramika',
				page: 'zs-keramika'
			},
			{
				title: 'Netradiční výtvarné dílny',
				page: 'netradicni-vytvarne-dilny'
			},
			{
				title: 'Malování a kreslení',
				page: 'malovani-a-kresleni'
			},
		]
	},
	{
		title: 'Naučné',
		image: {
			src: 'https://krouzky-kamarad.cz/img/ms/naucne.png',
			alt: ''
		},
		color: 'blue',
		list: [
			{
				title: 'Kulinářské vaření a pečení',
				page: 'kulinarske-vareni'
			},
			{
				title: 'Malý astronom',
				page: 'zs-maly-astronom'
			},
			{
				title: 'Šílený vědátor a badatel',
				page: 'sileny-vedator'
			},
			{
				title: 'Malý přírodovědec a skaut junior',
				page: 'skaut-junior'
			},
			{
				title: 'Kutil a modelář',
				page: 'kutil'
			},
			{
				title: 'Počítačový kroužek, programování a tvorba Youtube videí',
				page: 'pocitacovy-krouzek'
			},
			{
				title: 'Harry Potter, vítejte v Bradavicích!',
				page: 'harry-potter-vitejte-v-bradavicich'
			},
			{
				title: 'Módní tvorba',
				page: 'zs-modni-tvorba'
			},
			{
				title: 'Lego roboti',
				page: 'lego-roboti'
			},
		]
	},
	{
		title: 'Hudební',
		image: {
			src: 'https://krouzky-kamarad.cz/img/ms/hudebni.png',
			alt: ''
		},
		color: 'green',
		list: [
			{
				title: 'Hra na zobcovou flétnu',
				page: 'hra-na-zobcovou-fletnu'
			},
			{
				title: 'Hra na kytaru',
				page: 'hra-na-kytaru'
			},
			{
				title: 'Hrajeme a zpíváme s Djembe',
				page: 'hrajeme-a-zpivame-s-djembe'
			},
		]
	},
	{
		title: 'Divadelní',
		image: {
			src: 'https://krouzky-kamarad.cz/img/ms/divadelni.png',
			alt: ''
		},
		color: 'purple',
		list: [
			{
				title: 'Hudebně-dramatický kroužek',
				page: 'zs-hudebne-dramaticky-krouzek'
			},
			{
				title: 'Muzikálové herectví a tvůrčí psaní',
				page: 'zs-muzikalove-herectvi-a-tvurci-psani'
			},
		]
	},
	{
		title: 'Jazykové',
		image: {
			src: 'https://krouzky-kamarad.cz/img/ms/jazykove.png',
			alt: ''
		},
		color: 'pink',
		list: [
			{
				title: 'Český jazyk s Kamarádem',
				page: 'zs-logopedie'
			},
			{
				title: 'Angličtina – úroveň rodilý mluvčí',
				page: 'zs-anglictina-rodily-mluvci'
			},
			{
				title: 'Hravá angličtina',
				page: 'zs-hrava-anglictina'
			},
			{
				title: 'Francouzský jazyk',
				page: 'francouzsky-jazyk'
			},
			{
				title: 'Německý jazyk',
				page: 'nemecky-jazyk'
			},
			{
				title: 'Čínský jazyk',
				page: 'cinsky-jazyk'
			},
		]
	},
];
